
/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Любые изменения этого файла будут потеряны при следующей компиляции.
 * Любое изменение проекта без возможности компиляции ДОЛЬШЕ И ДОРОЖЕ в 2-5 раз.
 */

/* global require */

require('../blocks/alert/alert.js');
require('../blocks/cookie/cookie.js');
require('../blocks/detail/detail.js');
require('../blocks/dropdown/dropdown.js');
require('../blocks/filter-popup/filter-popup.js');
require('../blocks/form/form.js');
require('../blocks/form-checkbox/form-checkbox.js');
require('../blocks/form-enroll/form-enroll.js');
require('../blocks/form-popup/form-popup.js');
require('../blocks/form-send/form-send.js');
require('../blocks/gallery/gallery.js');
require('../blocks/header/header.js');
require('../blocks/input/input.js');
require('../blocks/lending/lending.js');
require('../blocks/main-page/main-page.js');
require('../blocks/map-popup/map-popup.js');
require('../blocks/result-slider/result-slider.js');
require('../blocks/review/review.js');
require('../blocks/search/search.js');
require('../blocks/select-filter/select-filter.js');
require('../blocks/tabs/tabs.js');
require('../blocks/time-popup/time-popup.js');
require('./script.js');

/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Любые изменения этого файла будут потеряны при следующей компиляции.
 * Любое изменение проекта без возможности компиляции ДОЛЬШЕ И ДОРОЖЕ в 2-5 раз.
 */

