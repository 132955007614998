import $ from "jquery";

$(function () {
  if ($(".gallery").length) {
    $(".gallery").each(function (i) {
      $(this)
        .find("[data-fancybox]")
        .attr("data-fancybox", "gallery-" + i);
    });
  }
});
