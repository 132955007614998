class StyleManager {
  constructor(initialVars = []) {
    this.initialVars = initialVars;
    this.headTag = document.getElementsByTagName("head")[0];
    this.styleTag = document.createElement("style");
    this.varMap = new Map();
    this.resizeObserver = null;
    console.log(initialVars);
    this.initialVars.forEach(([name, value, observerElem]) => {
      this.addStyleVar(name, value);
      if (observerElem) {
        this._observeResize(name, value, observerElem);
      }
    });
    this.headTag.appendChild(this.styleTag);
  }

  _renderStyleVar() {
    const entriesOfMap = Array.from(this.varMap.entries()).map((arr) =>
      arr.join(":")
    );
    this.styleTag.innerHTML = `
      :root {
        ${entriesOfMap.join(";")}
      }
    `;
  }
  _varMapSet(name, value) {
    if (typeof value === "function") {
      this.varMap.set(name, value());
    } else {
      this.varMap.set(name, value);
    }
  }

  addStyleVar(name, value) {
    this._varMapSet(name, value);
    this._renderStyleVar();
  }

  updateStyleVar(name, value) {
    if (!this.varMap.has(name)) {
      return;
    }
    this._varMapSet(name, value);
    this._renderStyleVar();
  }

  _observeResize(name, value, observerElem) {
    if (!this.resizeObserver) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target === observerElem) {
            this.updateStyleVar(name, value);
          }
        }
      });
      this.resizeObserver = resizeObserver;
    }

    this.resizeObserver.observe(observerElem);
  }
}

export default StyleManager;
